import { getToken } from "@/utils/auth";
import request from "../../utils/request";
//登录
export function getInfo() {
  return request.get("/api/v1/user");
}
export function getUsers(page,pagesize) {
  return request.get(`/api/v1/admin/allperson?page=${page}&page_long=${pagesize}`);
}
//修改用户中文名
export function updateChinaName(newchinaname) {
  return request.post("/api/v1/user/name", null, {
    params: {
      newchinaname,
    },
  });
}
//注册管理员
export function enrollAdmin(userName, password, chinaname, eMail) {
  return request.post("/api/v1/admin/enroll", {
    userName,
    password,
    chinaname,
    eMail,
  },{
    params:{
        captoken:getToken()
    }
  });
}
//设置管理员
export function setAdmin(userid){
   return request.post('/api/v1/admin/setadmin',null,{
    params:{
        userid
    }
   })
}
//删除用户
export function delUser(userid){
  return request.delete('/api/v1/admin/deluser',null,{
    params:{
        userid
    }
   })
}
