<template>
  <div>
    <el-row>
      <el-col :span="8">
        <el-card class="box-card" shadow="hover">
          <div class="user">
            <img src="@/assets/user.png" />
            <div class="userinfo">
              <el-tooltip
                class="item"
                effect="dark"
                :content="userInfo.chinaname"
                placement="top"
              >
                <p class="name">{{ userInfo.chinaname }}</p>
              </el-tooltip>
              <p v-if="userInfo.isadmin" class="access">超级管理员</p>
              <p v-else class="access">普通用户</p>
            </div>
          </div>
          <div class="login-info">
            <p>
              用户使用邮箱：<span>{{ userInfo.email }}</span>
            </p>
            <p>
              用户的序列号：<span>{{ userInfo.id }}</span>
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <el-row>
            <el-col :span="12">
              <el-input
                v-model="ChinaName"
                placeholder="请修改中文名"
              ></el-input>
              <el-button @click="updateName" type="primary">修改</el-button>
              <div class="logoshow">
                  <img src="@/assets/logo.png">
                  <span><i class="el-icon-s-tools"></i>本系统由ASG赛事组研发！</span>
              </div>
            </el-col>
            <el-col :span="1">
              <div class="line"></div>
            </el-col>
            <el-col :span="11">
              <el-input
                v-model="userName"
                placeholder="请输入用户名"
              ></el-input>
              <el-input v-model="password" placeholder="请输入密码"></el-input>
              <el-input v-model="eMail" placeholder="请输入邮箱"></el-input>
              <el-button @click="beAdmin" type="primary">成为管理员</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="height: 39vh" v-loading="loading">
      <el-col :span="24">
        <el-table :data="personList" border style="width: 100%">
          <el-table-column prop="id" label="序列号" width="70" align="center">
          </el-table-column>
          <el-table-column prop="user_group" label="用户群" width="200">
          </el-table-column>
          <el-table-column prop="chinaname" label="中文名" width="200">
          </el-table-column>
          <el-table-column prop="name" label="用户名" width="200">
          </el-table-column>
          <el-table-column
            prop="isadmin"
            label="权限"
            width="auto"
            :formatter="formatBoolean"
          >
          </el-table-column>
          <el-table-column prop="email" label="邮箱" width="200">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <button
                class="operationBtn"
                @click="handleSetAdmin(scope.row.id)"
              >
                设权
              </button>
              <button
                class="operationBtn"
                @click="handleDelUser(scope.row.id)"
              >
                删除
              </button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="5"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getInfo,
  getUsers,
  updateChinaName,
  enrollAdmin,
  setAdmin,
  delUser
} from "@/api/home";
export default {
  data() {
    return {
      personList: [],
      ChinaName: "",
      userName: "",
      password: "",
      chinaname: "",
      eMail: "",
      //加载
      loading: false,
      //分页
      currentPage: 0,
      total: 200,
    };
  },
  mounted() {
    this.initGetInfo();
    this.initGetUsers(1,5);
  },
  methods: {
    initGetInfo() {
      getInfo()
        .then((res) => {
          console.log(res.data);
          this.$store.commit("getUserInfo", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initGetUsers(page,pagesize) {
      this.loading = true;
      getUsers(page,pagesize)
        .then((res) => {
          console.log(res.data);
          this.personList = res.data;
          setTimeout(() => {
            this.loading = false;
          }, 800);
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.loading = false;
          }, 800);
        });
    },
    updateName() {
      updateChinaName(this.ChinaName)
        .then(() => {
          this.$message.success("修改成功！");
          this.initGetInfo();
        })
        .catch((err) => {
          this.$message.error(err.data);
        });
    },
    beAdmin() {
      enrollAdmin(this.userName, this.password, this.ChinaName, this.eMail)
        .then(() => {
          this.$message.success("成功！");
        })
        .catch((err) => {
          this.$message.error("失败" + err);
        });
    },
    // 设置管理员
    handleSetAdmin(id) {
      setAdmin(id)
        .then(() => {
          this.$message.success("设置成功！");
        })
        .catch(() => {
          this.$message.error('设置失败，请联系超级管理员！');
        });
    },
    // 删除用户
    handleDelUser(id){
      delUser(id)
      .then(() => {
          this.$message.success("删除成功！");
        })
        .catch(() => {
          this.$message.error('删除失败，请联系超级管理员！');
        });
    },
    //分页改变
    handleCurrentChange(val) {
      this.initGetUsers(val,5);
    },
    //formatter
    formatBoolean(row, column, cellValue) {
      var ret = ""; //你想在页面展示的值
      if (cellValue) {
        ret = "超级管理员"; //根据自己的需求设定
      } else {
        ret = "普通用户";
      }
      return ret;
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
};
</script>

<style scoped lang="less">
@btn-color:#53a0d4;
.el-row {
  margin-bottom: 20px;
}
.el-input {
  margin: 10px;
  width: 50%;
}
.el-button {
  margin: 0 10px;
  width: 50%;
}
.el-card {
  margin: 0 20px;
  height:38vh;
}
.line{
  height:30vh;
  width:2px;
  border-right:1px solid #ddd;
}
.logoshow{
  width:50%;
  margin:10px;
  padding:5px 50px 0;
  border-top:1px solid #ddd;
  img{
    width:70px;
    height:70px;
  }
  span{
    display: block;
    color:red;
    font-size: 14px;
  }
}
.operationBtn {
  text-align: center;
  line-height: 22px;
  float:left;
  height:22px;
  width: 40px;
  outline:none;
  margin: 0 3px;
  background-color: @btn-color;
  border-color:transparent;
  color: #fff;
  &:nth-child(2){
    background-color: red;
  }
  &:hover{
    background-color: #ddd;
    color:#333;
  }
}
.user {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  img {
    margin-right: 40px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .userinfo {
    .name {
      width: 150px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 32px;
      margin-bottom: 10px;
    }
    .access {
      color: #999999;
    }
  }
}

.login-info {
  p {
    line-height: 24px;
    font-size: 14px;
    color: #999;
    span {
      color: #666;
      margin-left: 60px;
    }
  }
}
</style>
